import React, { Component } from 'react';
import './App.css';
import { Divider, Icon, Select, List, ListItem, FormControl, InputLabel, MenuItem, ListItemText, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const WATT_OPTIONS = [ 110, 140, 170, 200, 230, 260, 290, 320, 350, 380, 410, 440, 470, 500, 530, 560, 590, 620 ];

const TIME_OPTIONS = [
  { seconds: 30, display: '0:30' },
  { seconds: 60, display: '1:00' },
  { seconds: 90, display: '1:30' },
  { seconds: 120, display: '2:00' },
  { seconds: 150, display: '2:30' },
  { seconds: 180, display: '3:00' },
  { seconds: 210, display: '3:30' },
  { seconds: 240, display: '4:00' },
  { seconds: 270, display: '4:30' },
  { seconds: 300, display: '5:00' }
];

const PACE_CONSTANTS = {
  100: 1,
  90: 1.03565217391,
  80: 1.07739130435,
  75: 1.10086956522,
  70: 1.12608695652,
  65: 1.1547826087,
  60: 1.18608695652
};

const PERCENTAGES = [ 60, 65, 70, 75, 80, 90, 100 ];

const INSTRUCTIONS = `To measure training zones, set the ergometer up for intervals of 5 minutes with 10 seconds rest.
  Do not warm up. Start the first interval at 110 average watts. Every 5 minutes, increase by 30 watts.
  Keep increasing wattage every interval until you can no longer hold the average wattage and dip below it for more than 10 strokes.
  Your final score will be the goal wattage for your last interval, and the amount of time you made it into the last interval before stopping.`

const styles = theme => ({
  container: {
    padding: '50px'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  footer: {
    marginTop: '60px'
  },
  help: {
    marginLeft: '10px',
    fontSize: '18px'
  }
});

class App extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      watts: WATT_OPTIONS[0],
      time: TIME_OPTIONS[0].seconds
    };

    this.changePower = this.changePower.bind(this);
    this.changeTime = this.changeTime.bind(this);
    this.calculateWatts = this.calculateWatts.bind(this);
    this.calculatePace = this.calculatePace.bind(this);
  }

  changePower(event) {
    this.setState({watts: event.target.value});
  }

  changeTime(event) {
    this.setState({time: event.target.value});
  }

  calculateWatts(percent) {
    let peakPower = this.calculatePeakPower();
    return peakPower * percent / 100;
  }

  calculatePace(percent) {
    let peakPower = this.calculatePeakPower();
    let pace = Math.pow(2.80/peakPower, 1/3);
    var totalSeconds = pace*500;
    var time = totalSeconds * PACE_CONSTANTS[percent];
    var minutes = Math.floor(time % 3600 / 60);
    var seconds = Math.floor(time % 3600 % 60);
    if(seconds < '10') {
      return `${minutes}:0${seconds}`
    } else {
      return `${minutes}:${seconds}`
    }
  }

  calculatePeakPower() {
    const { watts, time } = this.state;
    let peakPower;
    if(time === '300') {
      peakPower = watts;
    } else {
      peakPower = (watts - 30) + (3 *(time/30));
    }
    return peakPower;
  }

  render() {
    const { classes } = this.props;
    const { watts, time } = this.state;

    return (
      <div className={classes.container}>
        <h1>Rowing Step Test Calculator</h1>

        
          <h2>
            Step Test Results
            <Tooltip className={classes.help} title={INSTRUCTIONS} aria-label='intructions'>
              <Icon>info</Icon>
            </Tooltip>
          </h2>
        
        <form autoComplete="off">
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="max-watts">Max Watts</InputLabel>
            <Select
              placeholder="Watts"
              value={watts}
              onChange={this.changePower}
              inputProps={{
                name: 'watts',
                id: 'max-watts',
              }}
            >
              {WATT_OPTIONS.map(option => {
                return <MenuItem key={option} value={option}>{option}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="final-time">Final Time</InputLabel>
            <Select
              className={classes.select}
              placeholder="Time"
              value={time}
              onChange={this.changeTime}
              inputProps={{
                name: 'time',
                id: 'final-time',
              }}
            >
              {TIME_OPTIONS.map(option => {
                return <MenuItem key={option.seconds} value={option.seconds}>{option.display}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </form>
        <h2>Training Zones</h2>
        <List>
          {
            PERCENTAGES.map((percentage, index) => {
              let pace = (watts && time) ? `${this.calculatePace(percentage)}/500m ` : '';
              let wattage = (watts && time) ? `${this.calculateWatts(percentage)} watts` : '';
              return (
                <span key={percentage}>
                  {index === 0 && <Divider/>}
                  <ListItem>
                    <ListItemText>
                      <span>{percentage}%</span>
                    </ListItemText>
                    <ListItemText primary={pace} secondary={wattage}/>
                    
                  </ListItem>
                  <Divider/>
                </span>
              );
            })
          }
        </List>
        <div className={classes.footer}>
          built by <a href='https://www.danidewitt.com' target='_blank'>dani dewitt</a> at <a href='https://www.therowedahead.com' target='_blank'>the rowed ahead</a>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(App);
